module.exports = [{
      plugin: require('/Users/markoradak/repos/thehive/int-dxbee-website-2019/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000},
    },{
      plugin: require('/Users/markoradak/repos/thehive/int-dxbee-website-2019/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/markoradak/repos/thehive/int-dxbee-website-2019/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/markoradak/repos/thehive/int-dxbee-website-2019/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/Users/markoradak/repos/thehive/int-dxbee-website-2019/src/cms.js","manualInit":true},
    },{
      plugin: require('/Users/markoradak/repos/thehive/int-dxbee-website-2019/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
